@import 'utils';

.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.streaksButton {
  @include breakpoint(small down) {
    padding: rem-calc(4);
  }
}

.streaksModal {
  @extend %box-shadow;
  position: absolute;

  top: calc(100% + 20px);
  left: auto;
  right: rem-calc(28);
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: getColor($c-bg-neutral);
  opacity: 0;

  visibility: hidden;
  transition:
    transform $duration $easing,
    opacity $duration $easing,
    visibility 0s $duration;

  width: rem-calc(340);
  padding: rem-calc(24 24 18 24);
  border-radius: 8px;
  border: 1px solid getColor($c-border);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

  transform: translateY(-10px);

  @include dropdown-opened {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition-delay: 0s;
  }

  @include breakpoint(small down) {
    display: none;
  }
}

.pageOpenedState {
  background-color: getColor($c-bg-neutral-tertiary);
  border-color: getColor($c-border-hovered);
  padding: rem-calc(4);
  margin: rem-calc(0 12);
  min-height: 0;

  &:disabled {
    opacity: 1;
  }
}

.popover {
  @include breakpoint(small down) {
    display: none;
  }
}

.chubbsWrapper {
  position: relative;

  height: 120px;
  width: 100%;

  background-color: getColor($c-bg-brand);
}
.chubbs {
  @include absolute-center-x;

  bottom: 0;
}
