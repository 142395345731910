@import 'utils';

.sup, .sub {
  font-size: 85%;
}

.sup {
  vertical-align: super;
}

.sub {
  vertical-align: sub;
}

.details .summary {
  display: flex;
  align-items: center;
}

.summary::marker {
  content: '';
}

.openIcon {
  display: none;
}

.details .summary::-webkit-details-marker {
  display:none;
}

.closeIcon {
  display: inline;
}

details[open] .openIcon {
  display: inline;
}

details[open] .closeIcon {
  display: none;
}

.mark {
  font-weight: 800;
  color: getColor($c-text-brand);
  background-color: getColor($c-bg-brand-secondary);
}
