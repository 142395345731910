@import 'utils';

$arrow-size: rem-calc(16);

.wrapper {
  background-color: getColor($c-bg-neutral);
  border: 1px solid getColor($c-border);
  position: absolute;
  bottom: 0;
  right: 0;
  width: 336px;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: rem-calc(16);
  border-radius: 8px;
  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    width: $arrow-size;
    height: $arrow-size;
    border-radius: 2px;
    transform: rotate(45deg) translateY(75%);
    right: 20px;
    background-color: getColor($c-bg-neutral);

    border: 1px solid transparent;
  }
}

.topMode {
  bottom: calc(100% + #{$arrow-size});

  &::before {
    top: calc(100% - #{$arrow-size} - #{rem-calc(2)});
    border-color: transparent getColor($c-border) getColor($c-border) transparent;
  }
}

.bottomMode {
  bottom: auto;
  top: calc(100% + #{$arrow-size});

  &::before {
    top: auto;
    bottom: calc(100% + #{rem-calc(1)});
    border-color: getColor($c-border) transparent transparent getColor($c-border);
  }
}

.centerMode {
  left: 50%;
  transform: translateX(-50%);

  &::before {
    left: 50%;
  }
}

.leftSideMode {

  &::before {
    right: rem-calc(20);
  }
}

.rightSideMode {
  left: calc(50% - #{rem-calc(20)});

  &::before {
    left: rem-calc(20);
  }
}

.button {
  align-self: end;
}

.header {
  height: rem-calc(120);
  width: 100%;

  margin-bottom: rem-calc(8);
  overflow: hidden;
}

.heading {
  color: getColor($c-text-default);
}

.text {
  color: getColor($c-text-secondary);
}

