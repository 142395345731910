@import 'utils';

.container {
  position: relative;

  &:hover .copyButtonWrapper {
    opacity: 1;
  }
}

.pre {
  position: relative;
  min-height: 36px;
  tab-size: 2;

  scrollbar-width: thin;

  & ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  & ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  & ::-webkit-scrollbar-thumb {
    background-color: getColor($c-icon-secondary);
    border-radius: rem-calc(8);
  }
}

.copyButtonWrapper {
  position: absolute;
  top: rem-calc(1);
  right: rem-calc(8);

  opacity: 0;

  transition: opacity $duration;
}
